import { onMounted, defineComponent, reactive, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { doBind, doUnbind } from '@/api/code';
import { Toast, Dialog } from 'vant';
import { computed } from '@vue/reactivity';
import 'element-plus/es/components/drawer/style/css';
import { ElDrawer } from 'element-plus';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
export default defineComponent({
  name: 'Car',
  components: {
    ElDrawer
  },

  setup() {
    const state = reactive({
      form: {
        code_sn: '',
        license_plate_number: ['', '', '', '', '', '', ''],
        phone_number: '',
        is_bind: 0
      },
      event: 'change',
      show: false,
      // keyJC: [
      //   ['京', '粤', '津', '晋', '冀', '蒙', '辽', '吉', '黑', '渝', '青'],
      //   ['苏', '浙', '皖', '闽', '赣', '鲁', '鄂', '沪', '宁', '豫'],
      //   ['桂', '琼', '川', '贵', '云', '藏', '陕', '新', '湘', '甘'],
      // ],
      keyJC: [['京', '沪', '粤', '津', '冀', '豫', '云', '辽', '黑', '湘', '皖'], ['鲁', '苏', '浙', '赣', '鄂', '桂', '甘', '晋', '陕', '蒙', '吉'], ['闽', '贵', '渝', '川', '青', '琼', '宁', '藏', '新', '港', '澳']],
      normalKey: [['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'], ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'I', 'O', 'P'], ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'], ['Z', 'X', 'C', 'V', 'B', 'N', 'M']],
      lastKey: [['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'], ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'I', 'O', 'P'], ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', '警'], ['Z', 'X', 'C', 'V', 'B', 'N', 'M', '学']],
      index: 0,
      isNewEnergy: false // args: {},
      // callbacks: null,

    });
    const route = useRoute();
    const router = useRouter();

    const validator = val => {
      const reg = /^1\d{10}$/;
      return reg.test(val);
    };
    /**
     * 监听输入手机号
     * 输入手机号长度达到11位后弹窗提示
     */


    const onInputPhone = () => {
      if (validator(state.form.phone_number) && state.form.phone_number.length == 11) {
        Dialog({
          message: '请点击保存按钮即可绑定成功'
        });
      }
    };
    /**
     * 解绑
     */


    const onUnbind = () => {
      Dialog.confirm({
        title: '温馨提示',
        message: '确认要解绑挪车码？'
      }).then(async () => {
        const {
          status,
          msg
        } = await doUnbind({
          code_sn: state.form.code_sn
        });

        if (status == 1) {
          const toast = Toast.success('已解绑');
          setTimeout(() => {
            toast.clear();
            router.back();
          }, 1500);
        } else {
          Toast.fail(msg);
        }
      }).catch(() => {});
    };
    /**
     * 保存/绑定
     */


    const onSubmit = async () => {
      let {
        code_sn,
        license_plate_number,
        phone_number
      } = state.form; // 检查车牌位数

      license_plate_number = license_plate_number.join('');

      if (license_plate_number.length < 7 || license_plate_number.length > 8) {
        return Toast.fail('请输入正确车牌号');
      }

      const loading = Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      const {
        status,
        msg
      } = await doBind({
        code_sn,
        license_plate_number,
        phone_number
      });
      loading.clear();

      if (status == 1) {
        Toast.success('已保存');
        setTimeout(() => {
          router.back();
        }, 1500);
      } else {
        Toast.fail(msg);
      }
    };

    onMounted(() => {
      let data = route.query;

      if (data.license_plate_number) {
        data.license_plate_number = data.license_plate_number.split('');
        state.isNewEnergy = data.license_plate_number.length > 7;
      }

      state.form = Object.assign(state.form, data);
    });
    const chooseClass = computed(() => {
      return function (index) {
        if (state.show) {
          if (index == state.index) {
            return 'choose-class';
          } else {
            return '';
          }
        } else {
          return '';
        }
      };
    });
    const keys = computed(() => {
      let flag = state.index;

      if (flag == 0) {
        return state.keyJC;
      } else if (flag == 6) {
        return state.lastKey;
      } else {
        return state.normalKey;
      }
    });
    /**
     * 点击车牌号码
     */

    const handleCar = index => {
      state.index = index;
      state.show = true;
    };
    /**
     * 点击键盘内容
     */


    const handleKey = key => {
      let carNumber = state.form.license_plate_number;
      carNumber[state.index] = key;
      carNumber = [...carNumber];

      if (state.isNewEnergy) {
        if (state.index < 7) {
          state.index++;
        } else {
          state.show = false;
        }
      } else {
        if (state.index < 6) {
          state.index++;
        } else {
          state.show = false;
        }
      }
    };
    /**
     * 点击新能源
     */


    const newEnergy = () => {
      state.isNewEnergy = !state.isNewEnergy;
      state.form.license_plate_number[7] = '';
      state.index = 7;
      state.show = true;
    };

    const handleRemove = () => {
      state.form.license_plate_number[7] = '';
    };

    return { ...toRefs(state),
      onUnbind,
      onSubmit,
      validator,
      handleCar,
      handleKey,
      newEnergy,
      handleRemove,
      onInputPhone,
      chooseClass,
      keys
    };
  }

});